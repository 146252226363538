<template>
  <project-manager />
</template>

<script>
import ProjectManager from '@/components/projects/manager.vue';

export default {
  components: {
    ProjectManager,
  },
};
</script>
